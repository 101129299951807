import {combineReducers} from 'redux';
import authReducer from './authReducer';
import subReducer from './subReducer';
import messagesReducer from './messagesReducer';
import categoriesReducer from './categoriesReducer';
import contentsReducer from './contentsReducer';
import questionsReducer from './questionsReducer';

export default combineReducers({
  auth: authReducer,
  subscription: subReducer,
  messages: messagesReducer,
  categories: categoriesReducer,
  contents: contentsReducer,
  questions: questionsReducer
});