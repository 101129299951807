import { useEffect, useState } from "react";

const useStorage = (key, initialValue = null) => {
  const [value, setValue] = useState(null);

  const storeValue = (value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const removeValue = () => {
    localStorage.removeItem(key);
  };

  const getValue = () => {
    let value = localStorage[key];
    value = value ? JSON.parse(localStorage["udv_token_expiry"]) : value;

    return value;
  };

  useEffect(() => {
    setValue(JSON.parse(localStorage.getItem(key)) || initialValue);
  }, [initialValue, key]);

  return [value, storeValue, removeValue, getValue];
};

export default useStorage;
