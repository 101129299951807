import * as types from '../actions/types';

const initialState = {
  uniqueSubs: 0,
  totalSubs: 0,
  totalRenews: 0,
  totalUnSubs: 0,
  activeSubs: 0,
  subscribers: []
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case types.SET_SUBSCRIPTION_STATISTICS:
      return {
        ...state,
        uniqueSubs: action.data.unique_users,
        totalSubs: action.data.total_subscriptions,
        totalRenews: action.data.total_renews,
        totalUnsubs: action.data.total_unsubs,
        activeSubs: action.data.total_active_subscribers
      }
    case types.GET_SUBSCRIBERS_SUCCESS:
      return {
        ...state,
        subscribers: action.payload,
      }
    default:
      return state
  }
}

export default reducer;