import React from 'react';
import './pin-input.module.scss';
import PinInput from "react-pin-input";

const CustomPinInput = ({length, type, inputMode, initialValue, onChange, ...otherProps}) => {
    return(
        <div className="_">
            <PinInput 
                length={length} 
                initialValue={initialValue} 
                onChange={onChange} 
                type={type} 
                inputMode={inputMode}
                {...otherProps}
                />
        </div>
    )
}

export default CustomPinInput;