import {createStore, applyMiddleware, compose} from 'redux';

import thunk from 'redux-thunk';
import checkTokenExpiration from './middleware/tokenExpirationMiddleware';

import rootReducer from './reducers';

const middleware = [thunk, checkTokenExpiration];
const initialState = {}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
