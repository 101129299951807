/* import React from 'react';
import styles from './landing.module.scss'
import HeroSection from '../../../components/guest/HeroSection';
import AboutSection from '../../../components/guest/AboutSection';
import FooterSection from '../../../components/guest/FooterSection'; */

const LandingPage = () => {
    return(
        
            window.location = "/admin/signin"
        
    )
}


export default LandingPage;