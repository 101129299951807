import React from 'react';
import styles from './congratulations.module.scss'
import CongratulationsBox from '../../../components/guest/CongratulationsBox';
import fitnessLogo from '../../../assets/images/fitness_logo.svg'


const CongratulationsPage = () => {
    return(
        <div className={styles.body}>
            <div className={styles.header}> 
                <div className={styles.logo}>
                    <img src={fitnessLogo} alt=""/>
                </div>
            </div>
            <div className={styles.box_container}>
                <CongratulationsBox />
            </div>
        </div>  
    )
}

export default CongratulationsPage;