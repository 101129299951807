import React, { useEffect } from "react";
import Routes from "./routes";
import { useDispatch } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import useStorage from "./hooks/useStorage";
import { LOGOUT_SUCCESS } from "./redux/actions/types";

function App() {
  //   const [loadUserSuccess, loadUserError, isLoading, loadUser] = useFetch(`/users/${user &&user.id}?access_token=${token}`,
  //   {method: 'GET', headers: {
  //       'Content-Type': 'application/json',

  //   }},
  //   { actionType: types.LOAD_USER_SUCCESS, })
  // useEffect(() => {
  //   loadUser(null)
  // })
  const [, , , getExpiryTime] = useStorage("udv_token_expiry");
  const dispatch = useDispatch();

  useEffect(() => {
    const expiryTime = getExpiryTime();
    //log user out if expiry date/time has elapsed
    if (expiryTime && expiryTime < Date.now()) {
      dispatch({ type: LOGOUT_SUCCESS });

      const { origin } = window;
      window.location.href = `${origin}/admin/signin`;
    }
  }, []);

  return (
    <ToastProvider placement="bottom-right">
      <Routes />
    </ToastProvider>
  );
}

export default App;
