import React from 'react';

import styles from './form-input.module.scss';

const FormInput = ({handleChange, label, value, ...otherProps}) => {
    return(
        <div className={styles.form_container}>
            <label className={styles.form_label}>{label}</label>
            <input className={styles.form_input} onChange={handleChange} value={value||''} {...otherProps}/>
        </div>
    )
}

export default FormInput;