export const getToken = () => {
  let token = localStorage.getItem('udv_token');
  if (token) {
      return JSON.parse(token);
  } else {
      return null;
  }
};

export const getUserdata = () => {
  let userData = localStorage.getItem('udv_user');
  if (userData) {
      return JSON.parse(userData);
  }
  return null;
};
export const getUserPreference = () => {
  let userPreference = localStorage.getItem('udv_user_preference');
  if (userPreference) {
      return JSON.parse(userPreference);
  }
  return null;
};

export const deleteUser = () => {
  console.log("deleting storage items")
  localStorage.removeItem('udv_token');
  localStorage.removeItem('udv_user');
  localStorage.removeItem('udv_preference');
  localStorage.removeItem('udv_token_expiry');
};