/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useStorage from "./useStorage";
import * as types from "../redux/actions/types";
/**
 * @param {string} endpoint Endpoint
 * @param {object{}} [options = {}] Fetch options (optional)
 * @param {object{}} [reduxOptions = null] Redux options (optional) {actionType, callback}
 * @param {string} [baseURL = 'https://udv-api.iqubesolutions.com.ng/api/v1'] Base url (optional)
 *
 * @returns {object[]} [success, error, isLoading, dispatchFetch]
 */
export default (
  endpoint,
  options = {},
  reduxOptions = null,
  baseURL = "https://orange-api.iqubesolutions.com.ng/api/v1"
  //baseURL = "http://localhost:8000/api/v1"
) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const [token, storeToken, removeToken] = useStorage("udv_token");
  const [, storeExpiryTime, removeExpiryTime] = useStorage("udv_token_expiry");
  const [user, storeUser, removeUser] = useStorage("udv_user");

  const auth = useSelector((state) => state.auth);
  //hooks for updating and reading Redux state based on reduxOption callback
  const dispatch = useDispatch();
  const reduxState = useSelector(
    reduxOptions && reduxOptions.callback instanceof Function
      ? reduxOptions.callback
      : (state) => undefined
  );

  //Set default fetch options
  let predefinedOptions = {
    method: "GET",
    headers: {
      Accept: "application/json, text/plain, multipart/form-data, */*",
      "Content-Type": "application/json;charset=UTF-8",
      "X-Requested-With": "XMLHttpRequest",
    },
  };
  //Override options
  options = { ...predefinedOptions, ...options };

  useEffect(() => {
    //logout user if token as expired or doesn't exist
    if (response && response.error === "User Session not found") {
      removeToken();
      removeExpiryTime();
      removeUser();

      dispatch({
        type: types.LOGOUT_SUCCESS,
      });
    }
  }, [response]);

  //We return the dispatchFetch callback to actually perform the fetch. This could be used multiple times in the livecycle of a component
  const dispatchFetch = (body = new FormData(), append = "", params = []) => {
    const extra =
      params.length > 0
        ? params.reduce(
            (total, current) => total + `${current.title}=${current.value}`,
            ""
          )
        : "";
    setIsLoading(true);

    const fetchData = async () => {
      try {
        //Get Access Token
        const access_token = auth.token;

        // console.log({access_token})

        if (body instanceof FormData) {
          access_token && body.append("access_token", access_token);
          delete options.headers;
          options = options.method != "GET" ? { ...options, body } : options;
        } else if (body instanceof Object) {
          body = access_token ? { ...body, access_token } : body;
          options =
            options.method != "GET"
              ? { ...options, body: JSON.stringify(body) }
              : options;
        }

        const res = await fetch(baseURL + endpoint + append + extra, options);
        const json = await res.json();
        if (res.ok) {
          //Dispatch redux options action
          reduxOptions?.actionType &&
            dispatch({
              type: reduxOptions.actionType,
              payload: json?.data,
              data: json,
              auth: json?.auth,
            });

          if (json.auth) {
            if (json.data.type) {
              storeToken(json.auth.access_token);
              storeExpiryTime(new Date(json.auth.expires_at).getTime());
            }
            storeUser(json.data);
          }
          setError(null);
          setResponse(json);
          setIsLoading(false);
        } else {
          setError(json);
          setResponse(null);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setError(error);
        setResponse(null);
        setIsLoading(false);
      }
    };

    fetchData();
  };
  let success = reduxState || response;

  return [success, error, isLoading, dispatchFetch];
};
