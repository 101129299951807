import { getUserPreference } from '../../utils/tokenConfig';
import * as types from '../actions/types';

const all = [];

const initialState = {
  all,
  preference:getUserPreference()||[]
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case types.GET_ALL_CATEGORIES_SUCCESS:
      console.log(action)
      return {
        ...state,
        all: action.data
      }
    
    case types.USER_LOGIN_SUCCESS:
      console.log(action);
      action.data?.preference && localStorage.setItem('udv_user_preference',JSON.stringify(action.data.preference));
        return {
            ...state,
            preference: action.data?.preference || []
        }
    case types.GET_ALL_USER_PREFERENCE_SUCCESS:
        localStorage.setItem("udv_user_preference", JSON.stringify(action.data));
        return {
            ...state,
            preference: action.data
        }

    case types.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        preference: []
      }
    default:
      return state
  }
}

export default reducer;