import React from 'react';
import styles from './signup.module.scss'
import SignUpForm from '../../../components/guest/SignUpForm';
import fitnessLogo from '../../../assets/images/fitness_logo.svg'
import { PrimaryButton } from '../../../elements/Buttons';
import { withRouter } from "react-router-dom";



const SignUpPage = ({history}) => {
    return(
        <div className={styles.body}>
             <div className={styles.header}> 
                <div className={styles.logo}>
                    <img src={fitnessLogo} alt="" onClick={()=> history.push('/')}/>
                </div>
                <div className={styles.button_container}>
                    <PrimaryButton className={styles.subscribe_button} onClick={()=> history.push('/subscribe')}>Subscribe</PrimaryButton>
                </div>
            </div>
             <div className={styles.form_container}>
                <SignUpForm />
            </div>
        </div>
    )
}

export default withRouter(SignUpPage);