export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_PASSWORD_RESET_REQUEST = 'ADMIN_PASSWORD_RESET_REQUEST';
export const SET_SUBSCRIPTION_STATISTICS = 'SET_SUBSCRIPTION_STATISTICS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GET_MESSAGE_TYPES_SUCCESS = 'GET_MESSAGE_TYPES_SUCCESS';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const MESSAGE_UPDATE = 'MESSAGE_UPDATE';
export const TYPE_UPDATE = 'TYPE_UPDATE';
export const GET_CONTENTS_SUCCESS = 'GET_CONTENTS_SUCCESS';
export const GET_CONTENT_CATEGORIES_SUCCESS = 'GET_CONTENT_CATEGORIES_SUCCESS';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const GET_CONTENT_SUBCATEGORIES_SUCCESS = 'GET_CONTENT_SUBCATEGORIES_SUCCESS';
export const UPDATE_SUBCATEGORIES_SUCCESS = 'UPDATE_SUBCATEGORIES_SUCCESS';
export const GET_CONTENT_TYPES_SUCCESS = 'GET_CONTENT_TYPES_SUCCESS';
export const UPDATE_TYPE_SUCCESS = 'UPDATE_TYPE_SUCCESS';
export const CREATE_CONTENT_SUCCESS = 'CREATE_CONTENT_SUCCESS';
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTION_CATEGORIES_SUCCESS = 'GET_QUESTION_CATEGORIES_SUCCESS';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const QUESTION_CATEGORY_UPDATE = 'QUESTION_CATEGORY_UPDATE';
export const GET_SUBSCRIBERS_SUCCESS = 'GET_SUBSCRIBERS_SUCCESS';

//User types
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const CODE_VERIFICATION_SUCCESS = 'CODE_VERIFICATION_SUCCESS';
export const SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_SUCCESS';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const SUBSCRIPTION_CONFIRMATION_SUCCESS = 'SUBSCRIPTION_CONFIRMATION_SUCCESS';
export const GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS';
export const GET_ALL_USER_PREFERENCE_SUCCESS = 'GET_ALL_USER_PREFERENCE_SUCCESS';
export const SAVE_USER_PROFILE_SUCCESS = 'SAVE_USER_PROFILE_SUCCESS';
