import React, { lazy, Suspense } from "react";

import {
  Router,
  Route as DefaultRoute,
  Switch,
  Redirect,
} from "react-router-dom";

import { createBrowserHistory } from "history";
import LandingPage from "../containers/guest/Landing";

import SignUpPage from "../containers/guest/SignUp";
import SignInPage from "../containers/guest/SignIn";
import CongratulationsPage from "../containers/guest/Congratulations";
import CodeVerificationPage from "../containers/guest/CodeVerification";

const AdminRoutes = lazy(() => import("./AdminRoutes"));
const UserRoutes = lazy(() => import("./UserRoutes"));

const Routes = ({ isAuth, isAdmin }) => {
  const history = createBrowserHistory();

  const privateRouteRender = (Component, isAdminRoute, props) => {
    if (isAdminRoute) {
      if (isAuth && isAdmin) {
        return <Component {...props} />;
      }

      return (
        <Redirect
          to={{
            pathname: "/admin/signin",
          }}
        />
      );
    } else {
      if (isAuth) {
        return <Component {...props} />;
      }

      // toast.error("You've to be logged in to view that page.");
      return (
        <Redirect
          to={{
            pathname: "/signin",
          }}
        />
      );
    }
  };

  const nonAuthRouteRender = (
    Component,
    props,
    noAuthRequired = false,
    isAdminRoute
  ) => {
    if (isAdminRoute && noAuthRequired && isAuth && isAdmin) {
      return <Redirect to={{ pathname: "/admin/dashboard" }} />;
    }

    if (noAuthRequired && isAuth) {
      return <Redirect to={{ pathname: "/dashboard" }} />;
    }

    return <Component {...props} />;
  };

  const PrivateRoute = ({ component: Component, isAdminRoute, ...rest }) => (
    <DefaultRoute
      {...rest}
      render={(props) => privateRouteRender(Component, isAdminRoute, props)}
    />
  );

  const Route = ({
    component: Component,
    ensureNonAuth,
    isAdminRoute,
    ...rest
  }) => (
    <DefaultRoute
      {...rest}
      render={(props) =>
        nonAuthRouteRender(Component, props, ensureNonAuth, isAdminRoute)
      }
    />
  );

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={() => <LandingPage/>} />
        <Route
          ensureNonAuth
          path="/non-auth"
          component={() => <h2>You should not see this page if logged in</h2>}
        />
        <Route
          ensureNonAuth
          exact
          path="/signin"
          component={() => <SignInPage />}
        />
        <Route
          ensureNonAuth
          exact
          path="/signup"
          component={() => <SignUpPage />}
        />
        <Route
          ensureNonAuth
          exact
          path="/code-verification"
          component={() => <CodeVerificationPage /> }
        />
        <Route
          ensureNonAuth
          exact
          path="/congratulations"
          component={() => <CongratulationsPage /> }
        />
       
        <PrivateRoute
          exact
          path="/private"
          component={() => <h2>Private page</h2>}
        />

        {/* load all admin routes below */}
        <Suspense fallback={<div className="fullscreen_loading">Loading...</div>}>
          <AdminRoutes />
          <UserRoutes />
        </Suspense>
        <Route component={() => <p>Page not found</p>} />
      </Switch>
    </Router>
  );
};

export default Routes;
