import React, {useEffect, useState} from 'react';
import styles from './signup-form.module.scss';
import useFetch from "../../../hooks/useFetch";
import * as types from "../../../redux/actions/types";
import { useToasts } from "react-toast-notifications";
import { withRouter } from "react-router-dom";

import FormInput from '../../../elements/FormInput';
import { PrimaryButton } from '../../../elements/Buttons';
import { DefaultLink } from '../../../elements/Link';

const SignUpForm = ({history}) => {
    const [msisdn, setMsisdn] = useState(null);

    const [userRegisterSuccess, userRegisterError, isLoading, userRegister] = useFetch("/auth/register", 
    {method: 'POST', headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }},
    { actionType: types.USER_REGISTER_SUCCESS, })

    const { addToast } = useToasts();

    const handleUserRegister = () => {
        userRegister({ msisdn })
        localStorage.setItem("msisdn", msisdn);
    }

    useEffect(() => {
    if(userRegisterSuccess && userRegisterSuccess.status) {
      userRegisterSuccess && addToast(userRegisterSuccess.message, {
      appearance: "success",
      autoDismiss: true,
      autoDismissTimeout: 4000,
    })

    history.push("/code-verification");
    
    } else {
        userRegisterSuccess && addToast(userRegisterSuccess && userRegisterSuccess.error && userRegisterSuccess.error.msisdn[0], {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 4000,
        });
    }

    }, [userRegisterSuccess, addToast, history]);

    useEffect(() => {
    console.log({userRegisterError});

    userRegisterError && addToast(userRegisterError.message && userRegisterError.error || userRegisterError && userRegisterError.error.msisdn[0], {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 4000,
        });
    }, [userRegisterError, addToast]);

    return(
        <div className={styles._}>
            <div className={styles.form_container}>
                <div className={styles.form_header}>
                    <h3>Sign Up</h3>
                    <p>Watch the best training content and stay fit.</p>
                </div>
                <div className={styles.form_input_container}>
                    <FormInput 
                    value={msisdn}
                    label="Phone Number" 
                    placeholder="e.g 08142396025" 
                    onChange={(e) => setMsisdn(e.target.value)}
                    />
                </div>
                <p className={styles.form_info}>We would be sending your verification code to this number</p>
                <div className={styles.button_container}>
                    <PrimaryButton className={styles.sign_up_btn} loading={isLoading} onClick={handleUserRegister}>Sign Up</PrimaryButton>
                </div>
            </div>
            <div className={styles.login_link_container}> 
                <div className={styles.left_line}></div>
                <p>Returning Member? <DefaultLink to="/signin" className={styles.login_link}>Login</DefaultLink> </p> 
                <div className={styles.right_line}></div>
            </div>
        </div>
    )
}

export default withRouter(SignUpForm);