import React from 'react';
import styles from './button.module.scss'

export const PrimaryButton = ({children, className="", loading=false, ...props}) => {
    return (
        <button className={`${styles.primary} ${className}`} {...props}>
            {loading ? "Loading..." : children}
        </button>
    );
};
export const Button = ({children, className="", loading=false, ...props}) => {
    return (
        <button className={`${styles._} ${className}`} {...props}>
            {loading ? "Loading..." : children}
        </button>
    );
};

export default Button;