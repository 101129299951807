import * as types from '../actions/types';

const contents = null;
const categories = null;
const isUpdateCategory = false;
const subcategories = null;
const isUpdateSubcategory = false;
const contentTypes = null;
const contentTypesMap = {}
const isUpdateType = false;
const isUpdateContent = false;

const initialState = {
  contents,
  categories,
  isUpdateCategory,
  subcategories,
  isUpdateSubcategory,
  contentTypes,
  isUpdateType,
  contentTypesMap,
  isUpdateContent
};

const mapTypeToId = (types) => {
  let typeMap = {}

  types.forEach(type => {
    typeMap[type.id] = type
  });

  console.log({typeMap})

  return typeMap
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case types.GET_CONTENTS_SUCCESS:
      return {
        ...state,
        contents: action.data
      }
    case types.GET_CONTENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.data
      }
    case types.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isUpdateCategory: !state.isUpdateCategory
      }
    case types.GET_CONTENT_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        subcategories: action.data
      }
    case types.UPDATE_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        isUpdateSubcategory: !state.isUpdateSubcategory
      }
    case types.GET_CONTENT_TYPES_SUCCESS:
      return {
        ...state,
        contentTypes: action.data,
        contentTypesMap: mapTypeToId(action.data)
      }
    case types.UPDATE_TYPE_SUCCESS:
      return {
        ...state,
        isUpdateType: !state.isUpdateType
      }
    case types.UPDATE_CONTENT_SUCCESS:
      return {
        ...state,
        isUpdateContent: !state.isUpdateContent
      }
    default: 
      return state;
  }
}

export default reducer;