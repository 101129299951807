import React, {useEffect, useState} from 'react';
import styles from './code-verification-form.module.scss';
import FormInput from '../../../elements/FormInput';
import useFetch from "../../../hooks/useFetch";
import * as types from "../../../redux/actions/types";
import { useToasts } from "react-toast-notifications";
import { withRouter } from "react-router-dom";

import { PrimaryButton } from '../../../elements/Buttons';
import { DefaultLink } from '../../../elements/Link';
import CustomPinInput from '../../../elements/PinInput';

const VerificationCodeForm = ({history}) => {
    const pinInputLength = 4;
    const [pin, setPin] = useState(null);
    const msisdn = localStorage.getItem("msisdn");

    const [codeVerificationSuccess, codeVerificationError, isLoading, codeVerification] = useFetch("/auth/login", 
    {method: 'POST', headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }},
    { actionType: types.CODE_VERIFICATION_SUCCESS, })

    const { addToast } = useToasts();

    const handleCodeVerification = () => {
        codeVerification({ msisdn:msisdn, password:pin })
        console.log(pin)
    }

    useEffect(() => {
    if(codeVerificationSuccess && codeVerificationSuccess.status) {
      codeVerificationSuccess && addToast(codeVerificationSuccess.message, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 4000,
        })

    history.push("/subscribe");
    
    } else {
        codeVerificationSuccess && addToast(codeVerificationSuccess && codeVerificationSuccess.error && codeVerificationSuccess.error.msisdn[0], {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 4000,
        });
    }

    }, [codeVerificationSuccess, addToast, history]);

    useEffect(() => {
    console.log({codeVerificationError});

    codeVerificationError && addToast(codeVerificationError.message, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 4000,
        });
    }, [codeVerificationError, addToast]);


    return(
        <div className={styles._}>
            <div className={styles.form_container}>
                <div className={styles.form_header}>
                    <h3>Enter Verification Code</h3>
                    <p>We sent your verification code to {msisdn} </p>
                </div>
                <div className={styles.form_input_container}>
                    <CustomPinInput 
                        length = {pinInputLength}
                        type="numeric" 
                        inputMode="number" 
                        initialValue=""
                        onChange={(pin, index) => {setPin(pin)}} 
    
                        inputStyle={{borderColor:'#828282', backgroundColor:'#FFFFFF', width:'39px', height:'39px', color:'#000000', fontWeight:'bold', fontSize:"16px", borderRadius:'0px', margin:'5px'}}
                        inputFocusStyle={{border: '2px solid #27AE60', color:'#828282', fontWeight:'bold'}}
                        />
                </div>
                <p className={styles.form_info}>if you don’t receive the code in 30 seconds tap the button below to resend</p>
                <div className={styles.button_container}>
                    <PrimaryButton className={styles.sign_up_btn} onClick={ handleCodeVerification} loading={isLoading}>Continue</PrimaryButton>
                </div>
            </div>
            <div className={styles.login_link_container}> 
                <div className={styles.left_line}></div>
                <p>Code not received? <DefaultLink to="/" className={styles.login_link}>Resend</DefaultLink> </p> 
                <div className={styles.right_line}></div>
            </div>
        </div>
    )
}

export default withRouter(VerificationCodeForm);