import * as types from '../actions/types';
import * as tokenConfig from '../../utils/tokenConfig';
import moment from 'moment';

const user = tokenConfig.getUserdata();
const token = tokenConfig.getToken();
const isUser = user?.type === 1;
const isSubscribed = null;
const isAdmin = user?.type === 5;

const initialState = {
  user,
  token,
  isUser,
  isSubscribed,
  isAdmin
}

const reducer = (state = initialState, action) => {

  switch(action.type) {
    case types.ADMIN_LOGIN_SUCCESS:
      if(action.payload.type < 4){
        throw {name:"error", message: "You are not an admin"};
      }
      return {
        ...state,
        isAdmin: true,
        user: action.payload,
        token: action.auth.access_token
      }
    case types.LOGOUT_SUCCESS:
      tokenConfig.deleteUser();
      return {
        ...state,
        isAdmin: false,
        isUser: false,
        token: '',
        user: ''
      }
    case types.USER_REGISTER_SUCCESS:
      return {
        ...state,
        isUser: true,
        user: action.payload,
        
      }
    case types.SAVE_USER_PROFILE_SUCCESS:
      localStorage.setItem("udv_user", JSON.stringify(action.payload));
    return {
      ...state,
      user: action.payload,
    }
    case types.USER_LOGIN_SUCCESS:
    case types.CODE_VERIFICATION_SUCCESS:
    case types.LOAD_USER_SUCCESS:
      return {
        ...state,
        isUser: true,
        user: action.payload,
        token: action.auth ? action.auth.access_token : state.token
          
      }

    case types.SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          isUser: true,
          user: action.payload,
      
    }
    case types.SUBSCRIPTION_CONFIRMATION_SUCCESS:
      const update_type = action.payload?.update_type;
      const sub_type = action.payload?.update_type;
      const expired = moment(action.payload?.update_type, "YYYY-MM-DD HH:mm:ss").diff(moment())
        return {
          ...state,
          isSubscribed : true,
        }
    
    case types.USER_LOGOUT_SUCCESS:
        localStorage.removeItem('udv_token');
        localStorage.removeItem('udv_user');
        localStorage.removeItem('udv_user_preference');
      return {
        ...state,
        isUser: false,
        user:null,
        token:null
      }
    default:
      return state;
  }
}

export default reducer;