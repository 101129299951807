import * as types from "../actions/types";

const questions = null;
const questionsCategoriesMap = {};
const categories = null;
const isCategoryUpdate = false

const initialState = {
  questions,
  questionsCategoriesMap,
  categories,
  isCategoryUpdate
};

const mapQuestionsToCategories = (questions) => {
  const map = {};

  questions.forEach((question) => {
    if (question.category?.id in map) {
      map[question.category?.id].questions.push(question);
    } else {
      map[question.category?.id] = {
        title: question.category?.category,
        questions: [question],
      };
    }
  });

  return map;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_QUESTIONS_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        questions: action.payload,
        questionsCategoriesMap: mapQuestionsToCategories(action.payload),
      };
    case types.GET_QUESTION_CATEGORIES_SUCCESS:
      console.log("CAT", action.payload)
      return {
        ...state,
        categories: action.payload
      };
    case types.QUESTION_CATEGORY_UPDATE:
      return {
        ...state,
        isCategoryUpdate: !state.isCategoryUpdate
      }
    default:
      return state;
  }
};

export default reducer;
