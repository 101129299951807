import { LOGOUT_SUCCESS } from "../actions/types";

const checkTokenExpiration = () => (next) => (action) => {
  let expiryTime = localStorage["udv_token_expiry"];
  expiryTime = expiryTime
    ? JSON.parse(localStorage["udv_token_expiry"])
    : expiryTime;

  //log user out if expiry date/time has elapsed
  if (expiryTime && expiryTime < Date.now()) {
    next({ type: LOGOUT_SUCCESS });

    const { origin } = window.location;
    window.location.href = `${origin}/admin/signin`;
  }
  next(action);
};

export default checkTokenExpiration;
