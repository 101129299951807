import React, {useEffect, useState} from 'react';
import styles from './signin-form.module.scss';
import useFetch from "../../../hooks/useFetch";
import * as types from "../../../redux/actions/types";
import { useToasts } from "react-toast-notifications";
import { withRouter, Redirect } from "react-router-dom";
import FormInput from '../../../elements/FormInput';
import { PrimaryButton } from '../../../elements/Buttons';
import { DefaultLink } from '../../../elements/Link';
import { connect } from 'react-redux'

const SignInForm = () => {

    const [msisdn, setMsisdn] = useState(null);
    const [pin, setPin] = useState(null);

    const [{isUser,isAdmin}, userLoginError, isLoading, userLogin] = useFetch("/auth/login", 
    {method: 'POST', headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }},
    { actionType: types.USER_LOGIN_SUCCESS, callback: globalState =>  globalState.auth })

    const { addToast } = useToasts();

    const handleUserLogin = e => {
        e.preventDefault();
        userLogin({ msisdn, password:pin })
    }

    useEffect(() => {
        userLoginError && addToast(userLoginError.message || userLoginError.error, {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 4000,
            });
        }, [userLoginError, addToast]);
    
    return(
        isUser && !isAdmin 
        ?
        <Redirect to="/dashboard"/>
        :
        <div className={styles._}>
            <form onSubmit={handleUserLogin} className={styles.form_container}>
                <div className={styles.form_header}>
                    <h3>Welcome Back!</h3>
                    <p>Please type in the required number and PIN sent to your device</p>
                </div>
                <div className={styles.form_input_container}>
                    <FormInput 
                        value={msisdn}
                        label="Phone Number" 
                        placeholder="e.g 08142396025" 
                        onChange={(e) => setMsisdn(e.target.value)}/>
                    <br />
                    <FormInput
                        type="password"
                        value={pin} 
                        label="PIN" 
                        placeholder="Sent to your device" 
                        onChange={(e) => setPin(e.target.value)}/>
                </div>
                <div className={styles.button_container}>
                    <PrimaryButton type="submit" className={styles.sign_up_btn}  loading={isLoading}>Login</PrimaryButton>
                </div>
            </form>
            <div className={styles.login_link_container}> 
                <div className={styles.left_line}></div>
                <p> New to this site? <DefaultLink to="/signup" className={styles.login_link}>Sign Up</DefaultLink> </p> 
                <div className={styles.right_line}></div>
            </div>
        </div>
    )
}


export default SignInForm;