import * as types from '../actions/types';

const messageTypes = null;
const messages = null;
const messageTypesMap = null;
const messageUpdate = false;
const typeUpdate = false;

const initialState = {
  messageTypes,
  messageTypesMap,
  messages,
  messageUpdate,
  typeUpdate
};

const mapTypeToId = (types) => {
  let typeMap = {}

  types.forEach(type => {
    typeMap[type.id] = type
  });

  return typeMap;
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case types.GET_MESSAGE_TYPES_SUCCESS:
      return {
        ...state,
        messageTypes: action.data,
        messageTypesMap: mapTypeToId(action.data)
      }
    case types.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.data
      }
    case types.MESSAGE_UPDATE:
      return {
        ...state,
        messageUpdate: !state.messageUpdate
      }
    case types.TYPE_UPDATE:
      return {
        ...state,
        typeUpdate: !state.typeUpdate
      }
    default:
      return state
  }
}

export default reducer;