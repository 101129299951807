import React from 'react';
import styles from './code-verification.module.scss'
import VerificationCodeForm from '../../../components/guest/CodeVerificationForm';
import fitnessLogo from '../../../assets/images/fitness_logo.svg';

const CodeVerificationPage = () => {
    return(
        <div className={styles.body}>
            <div className={styles.header}> 
                <div className={styles.logo}>
                    <img src={fitnessLogo} alt=""/>
                </div>
            </div>
             <div className={styles.form_container}>
                <VerificationCodeForm />
            </div>
        </div>
    )
}

export default CodeVerificationPage;