import React from 'react';

import styles from './congratulations-box.module.scss';
import congratsMark from '../../../assets/images/congrat_mark.svg';

const CongratulationsBox = () => {
    return(
        <div className={styles._}>
            <div className={styles.container}>
                <div className={styles.congrats_text}>
                    <img src={congratsMark} alt="congratulations mark"/>
                    <p>Congratulations!</p> 
                </div>
                <div className={styles.congrats_info}>
                    <p>You have been successfully subscribed to this service, please hold on while we redirect you...</p> 
                </div>
            </div>
        </div>
    )
}

export default CongratulationsBox

