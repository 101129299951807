import React from 'react';
import styles from './link.module.scss'
import { Link } from 'react-router-dom';

export const PrimaryLink = ({children="", className="", style={}, to=""}) => {
    return (
        <Link className={`${className} ${styles.primary}`} {...{style, to}}>
            {children}
        </Link>
    );
};
export const DefaultLink = ({children, className, style, to}) => {
    return (
        <Link className={`${className} ${styles._}`} {...{style, to}}>
            {children}
        </Link>
    );
};

export default DefaultLink;